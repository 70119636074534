import React from "react"
// import { useState, useEffect } from 'react';

const UseWpContentTest = () => {

  const site_url = 'https://test.qlea.jp/ogawa/gatsby-wp/wordpress/';//ここにWordPress本体のURLを記述
  const posts_url = site_url + 'wp-json/wp/v2/posts/?_embed';//ただの投稿一覧を取得する場合のREST API記述

  // console.log(site_url);
  // console.log(posts_url);

  let html

  fetch(posts_url)
    .then((response) => response.json())
    .then((data) => {
      data.forEach(item => {
        html = `
        <div>
          投稿日時：${item.date}
          パーマリンク：${item.link}
          タイトル：${item.title.rendered}
        </div>
      `
        // console.log(html)
      });
    });


  // const [data, setData] = useState([]);
  // useEffect(() => {
  //   fetch(posts_url)
  //     .then(response => setData(response.data))
  //     .catch(error => console.log(error));

  //   console.log(data);
  // }, []);

  return (
    <>
      {/* {data.map((item, index) => (
       <li key={index}>
         <a href={item.link}>
           <date>{item.date}</date>
           <p>{item.title.rendered}</p>
           <p>{item.content.rendered}</p>
         </a>
       </li>
     ))} */}
     <p>useWpContentTest</p>
    </>
  )
}

export default UseWpContentTest
