import React from "react"
import { Router } from "@reach/router"
import Helmet from "react-helmet"
import UseWpContentTest from "../components/UseWpContentTest"

const wpContent = () => (
  <>
  <Helmet>
    <script src="/wp-api/getContent.js"></script>
  </Helmet>
  <main>
    <Router>
      <UseWpContentTest path="/wp-content/test" />
    </Router>

    <div id="wpContentBox">
    </div>

    ここにWP記事
  </main>
  </>
)
export default wpContent